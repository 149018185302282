// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__HXQOm {\n    width: 100%;\n    height: 40px;\n    background-color: black;\n    color: whitesmoke;\n    font-size: 48px;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".header {\n    width: 100%;\n    height: 40px;\n    background-color: black;\n    color: whitesmoke;\n    font-size: 48px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__HXQOm"
};
export default ___CSS_LOADER_EXPORT___;
